import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({ 
    name: 'moment',
    standalone: true 
})
export class MomentPipe implements PipeTransform {
    transform(value: string | Date | moment.Moment, dateFormat: string): any {
        if(!value) return ' ';
        
        return moment(value).format(dateFormat);
    }
}